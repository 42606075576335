<template>
  <div>
    <!--pc端-->
    <div class="pc-topbar">
      <div class="topbar-left">
        <router-link to="/index">
          <div class="logo">
            <img src="@/assets/images/logo.png" alt="">
          </div>
        </router-link>
        <div class="navigation">
          <div class="nav-box" v-for="(item,index) in tabList" :key="index">
            <div class="label">
              <div class="label-box">
                <p>{{ item.title }}</p>
              </div>
            </div>
            <div class="menu">
              <section class="menu-left">
                <span>{{ item.subTitle }}</span>
                <p>{{ item.explain }}</p>
              </section>
              <section class="menu-center">
                <div class="menu-center-container" v-for="(tag,num) in item.tags" :key="num" @click.stop="onPageJump(tag.path,tag.id)">
                  <span>{{ tag.name }}</span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div class="topbar-right" v-show="false">
        <div class="search">
          <div class="search-input" :class="searchShow?'':'hidden'">
            <input class="ipt" ref="search" type="text" :model="searchValue" placeholder="搜索" @blur="closeSearch">
            <div class="close" @click="closeSearch">
              <img src="@/assets/images/search_close.png" alt="">
            </div>
          </div>
          <div class="search-icon" :class="searchShow?'hidden':''" @click="openSearch">
            <img src="@/assets/images/search.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!--移动端-->
    <div class="mobile-topbar">
      <div class="topbar-box">
        <div class="logo" @click="onPageJump('/index','')">
          <img src="@/assets/images/logo.png" alt="">
        </div>
        <div class="menu" :class="openMobileMenu?'menu-open':'menu-close'" @click="closeMobileMenu">
          <div class="menu-switch">
            <span :class="openMobileMenu?'menu-switch-open':''"></span>
          </div>
        </div>
        <div class="sidebar" :style="{'visibility': openMobileMenu?'visible':'hidden'}">
          <div class="sidebar-box" :class="openMobileMenu?'sidebar-open':''">
            <div class="tabs">
              <div class="tabs-box" v-for="(item,index) in tabList" :key="index">
                <div class="option" @click.stop="onSelectTab(index)">
                  <span class="option-name">{{ item.title }}</span>
                  <div class="option-icon">
                    <img src="@/assets/images/right_arrow.png" alt="">
                  </div>
                </div>
                <div class="content" :class="tabIndex === index?'content-open':'content-close'">
                  <div class="content-back" @click.stop="tabIndex = 100">
                    <div class="content-back-icon">
                      <img src="@/assets/images/left_arrow.png" alt="">
                    </div>
                    <span>返回</span>
                  </div>
                  <div class="content-box">
                    <section class="box-top">
                      <span>{{ item.subTitle }}</span>
                      <p>{{ item.explain }}</p>
                    </section>
                    <section class="box-center">
                      <div class="box-center-container" v-for="(tag,num) in item.tags" :key="num" @click.stop="onPageJump(tag.path)">
                        <span>{{ tag.name }}</span>
                      </div>
                    </section>
                    <!--                    <section class="box-bottom">-->
                    <!--                      <span class="other">其他产品</span>-->
                    <!--                      <a href="">-->
                    <!--                        <span>乌托邦引擎</span>-->
                    <!--                      </a>-->
                    <!--                    </section>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="search" v-show="false">
              <div class="search-box">
                <div class="icon">
                  <img src="@/assets/images/search.png" alt="">
                </div>
                <input class="ipt" type="text" :model="searchValue" placeholder="搜索">
              </div>
            </div>
          </div>
        </div>
        <div class="mask" v-show="openMobileMenu" @click="closeMobileMenu"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchShow: false,
      searchValue: '', // 搜索关键字
      openMobileMenu: false, // 移动端菜单显隐
      tabList2: ['IP-BOX', '乌托邦引擎', '资产商城', '解决方案', '新闻活动', '支持'],
      tabList: [{
        title: 'IP-BOX',
        subTitle: 'IP-BOX',
        explain: '目前全球最轻量级的制片机，操作极其容易，无需专业人才方可操作。100%由中国程序员自主研发的人类历史第一台虚拟制片一体机伴随人类进行元宇宙的时空探索连接简单、界面友好、价格亲民跳楼式降低人类探索元宇宙门槛；全球首款声光电融合、虚实交互结合的制片一体机，大大降低学校政企、小微品牌和直播领域的采购成本。',
        tags: [
          {
            name: '设备',
            path: '/home/index',
          }, {
            name: '功能介绍',
            path: '/detail',
            id: 4,
          }
        ]
      }, {
        title: '乌托邦引擎',
        subTitle: '乌托邦引擎',
        explain: '动静结合，小空间、大世界，一机多位，易操作、秒融合彻底打破好莱坞千万级制作简单得就像玩弄电视游戏机。全球首款人工智能虚拟制片引擎，“你”三十分钟即可轻松掌控，大大降低元宇宙制片的进入成本。',
        tags: [{
          name: '功能',
          path: '/detail',
          id: 5,
          // }, {
          //   name: '使用说明',
          //   id: 1,
        }]
      },
        // , {
        //   title: '资产商城',
        //   subTitle: 'Showings首映式',
        //   explain: '这是首映式的简介',
        //   tags: [{
        //     name: '功能',
        //     id: 3,
        //   }]
        // }, {
        //   title: '解决方案',
        //   subTitle: '解决方案',
        //   explain: '想象解放，创意无界。',
        //   tags: [{
        //     name: '文化旅游',
        //     id: 1,
        //   }, {
        //     name: '电子商务',
        //     id: 1,
        //   }, {
        //     name: '线上发布会',
        //     id: 1,
        //   }, {
        //     name: '摄影微电影',
        //     id: 1,
        //   }, {
        //     name: '娱乐综艺',
        //     id: 1,
        //   }]
        // },
        {
          title: '新闻',
          subTitle: '查看新闻',
          explain: '查看最新新闻，认识利用IP-BOX和乌托邦引擎进行创新的焦点人物。',
          tags: [{
            name: '查看',
            path: '/news',
          }]
        }
        , {
          title: '支持',
          subTitle: '支持',
          explain: '无论你是新人还是专家，我们都能帮助你取得成功。',
          tags: [{
            name: '常见问题',
            path: '/question',
            id: 1,
          }]
        }
      ],
      tabIndex: 100,
      contentShow: false,
    }
  },
  created() {

  },
  methods: {
    // 路由跳转
    onPageJump(path, id) {
      this.openMobileMenu = false;
      this.$router.push({
        path,
        query: {
          id,
        },
      });
    },
    // 打开搜索框
    openSearch() {
      this.searchShow = true;
      this.$nextTick(() => {
        this.$refs.search.focus();
      })
    },
    // 关闭搜索框/搜索框失去焦点
    closeSearch() {
      this.searchShow = false;
      this.searchValue = '';
    },
    // 关闭移动端菜单栏
    closeMobileMenu() {
      this.openMobileMenu = !this.openMobileMenu;
      this.tabIndex = 100;
    },
    // 移动端选择Tab
    onSelectTab(index) {
      this.tabIndex = index;
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/TopBar.less";
</style>
