import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import "swiper/swiper.min.css";

import 'element-ui/lib/theme-chalk/index.css'
import elementUI from './utils/elementui.js'
Vue.use(elementUI);

// 跳转页面
Vue.prototype.pageJump = (path,id) => {
  router.push({
    path,
    query: {
      id,
    },
  });
}

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.scrollTo(0, 0); //兼容IE
  next();
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
