<template>
  <div id="app">
    <TopBar></TopBar>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import TopBar from './components/TopBar/TopBar'
import Footer from './components/Footer/Footer'
export default {
  name: "App",
  components: {
    TopBar,
    Footer,
  }
};
</script>

<style lang="less">
@import "assets/css/RichText";
@import "assets/css/FaqText";
</style>
