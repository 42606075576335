import Vue from "vue";
import VueRouter from "vue-router";

// 冗余导航
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

// 路由表
const routes = [
  // {
  //   path: "/",
  //   redirect: "/home/index",
  //   component: () => import("@/views/home/index"),
  // },
  {
    path: "/home/index",
    name: "Index",
    component: () => import("@/views/home/index"),
  },
  {
    path: "/",
    redirect: "/index",
    component: () => import("@/views/index"),
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("@/views/index"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("@/views/news"),
  },
  {
    path: "/question",
    name: "Question",
    component: () => import("@/views/question"),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () => import("@/views/detail"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
