import {
  Carousel,
  CarouselItem
} from 'element-ui'

let components = {
  Carousel,
  CarouselItem
}

function install(Vue) {
  Object.keys(components).forEach(key => Vue.use(components[key]))
}

export default { install }