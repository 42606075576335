<template>
  <div>
    <div class="footer">
      <div class="footer-container">
        <div class="footer-container-links">
          <div class="links-wrap">
            <div class="links-wrap-column">
              <div class="column-title">支持</div>
              <div class="column-title-container" @click="pageJump('/news')">
                <span>新闻资讯</span>
              </div>
              <div class="column-title-container" @click="pageJump('/question')">
                <span>常见问题</span>
              </div>
            </div>
            <div class="links-wrap-column">
              <div class="column-title">公司</div>
              <div class="column-title-container" @click="pageJump('/home/index')">
                <span>IP-BOX</span>
              </div>
            </div>
          </div>
          <div class="links-contact">
            <div class="links-contact-code">
              <img src="@/assets/images/code.png" alt="">
            </div>
            <div class="links-contact-info">
              <span>&nbsp;联系方式</span>
              <div class="info-phone">
                <div class="info-phone-icon">
                  <img src="@/assets/images/phone.png" alt="">
                </div>
                <div class="info-phone-number">18948745908</div>
              </div>
              <p>&nbsp;周一至周五：7:00-21:00</p>
              <p>&nbsp;周末及节日：10:00-19:00</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="footer-container-copyright">
          Copyright 2022 深圳市超元创世科技有限公司 · 联系电话 18948745908 · 地址 广东省深圳市泰然五路9福田天安科技创业园B座8层801 ·
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022144574号</a>
        </div>
        <div class="footer-container-agree">
          <div class="agree-links">
            <a class="record" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009647" target="_blank">
              <img src="@/assets/images/beian_icon.png" alt=""/>
              粤公网安备 44030502009647号
            </a>
<!--            <a href="">-->
<!--              <span>服务条款</span>-->
<!--            </a>-->
<!--            <a href="">-->
<!--              <span>隐私政策</span>-->
<!--            </a>-->
          </div>
          <div class="logos">
            <div class="logos-container">
              <img src="@/assets/images/utopia.png" alt="">
            </div>
            <div class="logos-container">
              <img src="@/assets/images/ipbox.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
@import "../../assets/css/Footer.less";
</style>
